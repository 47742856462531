import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const DiscografiaLaPalabra = () => {
  return (
    <Layout>
      <GatsbySeo
        title="La Palabra – 2006"
        titleTemplate="%s | Eduardo Ceballos"
        description="La Palabra – 2006"
        canonical="https://eduardoceballos.com/discografia/la-palabra"
        openGraph={{
          url: `https://eduardoceballos.com/discografia/la-palabra`,
          title: `La Palabra – 2006`,
          description: `La Palabra – 2006`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/discografia.jpg`,
              alt: `La Palabra – 2006`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>La Palabra – 2006</h1>
      <center>
        <img
          className="discos-img"
          style={{ float: "Right", marginLeft: "10px" }}
          src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdlapalabra_09e5996d44.jpg"
          alt=""
        />
      </center>
      <div>
        <p>
          La Música resuena cuando el poeta Eduardo Ceballos convoca a La
          Palabra, La Sangre fluye en él, llega desde Lo Cósmico,
          transformándose en un Canto Antiguo. Dibuja la presencia de El Arbol y
          de El Hachero, y comprende La Tierra Elemento Prohibitivo.
        </p>
        <p>
          Y Para Enamorarte, recorre El Mapa en un Ciclomundo infinito que
          acorta El Tiempo y La Distancia. Viaja a ese entonces en que América
          Era Verde y pasa por los paisajes mágicos de nuestra Salta. Y así
          llega, en fraternal abrazo con lo ancestral y milenario, al Puerto de
          los Poetas.
        </p>
        <h6 style={{ textAlign: "right" }}>Viviana Cristina Ceballos.</h6>
        <p>
          El prestigioso cantor popular <strong>Zamba Quipildor</strong> que
          llevó la cultura argentina por el mundo, pone su presencia en este
          trabajo discográfico para acompañar a la poesía y a su compadre
          Eduardo Ceballos.
        </p>
        <p>
          Zamba, visitó los pueblos y las razas como embajador de la patria.
        </p>
        <p>
          Su canto sigue recorriendo geografías y su voz colecciona admiradores
          por todos los caminos.
        </p>
        <p>
          La Misa Criolla de Ariel Ramírez es su bandera y con su autor la
          ofreció en los sitios más privilegiados de la tierra: El Vaticano, El
          Muro de los Lamentos en Israel, el Teatro de la Reina en Londres,
          España, Estados Unidos.
        </p>
        <p>Como una señal de afecto y de amistad comparte esta grabación.</p>
        <p>
          <strong>Rubén Pérez</strong>, nacido en El Tala, provincia de Salta,
          se destaca como música popular y como compositor. Su tema más conocido
          “Mi Taleñita” fue grabado por muchos interpretes, entre los que se
          destacan: Las Voces de Orán, Los Amigos, El Chaqueño Palavecino.
          Compuso con Daniel Toro “El Cielo de la Baguala”, tema cantado por
          muchos artístas.
        </p>
        <p>
          Publicó con la poetisa española Chus Feteira el libro “Poemas
          Transoceánicos”, que editó el InCA y prologó Eduardo Ceballos.
        </p>
        <p>
          Realizó numerosas giras por Europa, donde editó trabajos
          discográficos, entre los que se destaca “Taleño por naturaleza” y
          “Preludio para mi madre”.
        </p>
        <p>
          Con el poeta Eduardo Ceballos comparten una amistad que se origina en
          la esquina frutal de la infancia. Desde ese tiempo viene trabajando
          por la cultura de Salta.
        </p>
        <p>
          <strong>Agradecimientos</strong>
        </p>
        <p>
          Este trabajo, primero fue un sueño íntimo, personal, luego a través de
          los amigos se sintió multiplicado y plural.
          <br />
          Jorge Gianella, René López, Carlos Ceballos, Rubén Zusman, Carmelo
          Perri, Gustavo Castañares, Vicente Fili, Julio G. Jovanovich, Oscar
          Finotti, Gerardo Monterichel, Celia de Morizzio, Carlos Alberto
          Alurralde, Eduardo Yarad y Juan Balderrama, son los que aportaron para
          hacer aquel proyecto.
          <br />
          Camino por la vida con la magia de la amistad que me acaricia, me
          envuelve y me acompaña en esta bella tierra.
          <br />
          Fundamental lo realizado por mi hija Viviana Cristina Ceballos, para
          entregar este producto.
        </p>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default DiscografiaLaPalabra
